import $ from 'jquery';

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        var now = Date.now();
        if (!previous && options.leading === false) previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}

$(document).ready(function () {
    const isMacLike = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
    const secondKey = isMacLike ? 'metaKey' : 'ctrlKey';

    const searchParams = new URLSearchParams(window.location.search);
    setTimeout(function () {
        const scrolled = window.pageYOffset > 0;
        if (!scrolled) {
            if (searchParams.has('page')) {
                const $dataPage = $(`[data-page="${searchParams.get('page')}"]`);
                if ($dataPage.length) {
                    const node = $dataPage.get(0);
                    window.scrollTo(0, node.offsetTop - node.parentNode.offsetTop);
                }
            }
        }
    }, 400);

    const $dataPages = $('[data-page]');
    if ($dataPages.length) {
        let currentPage = parseInt(searchParams.get('page') || 1);
        const $nextPage = $('[data-toggle="next-page"]');
        const loadUrl = function (url) {
            if (url) {
                $.getScript(url)
            }
        };

        const loadNext = function () {
            const nextUrl = $nextPage.attr('href');
            if (nextUrl) {
                $nextPage.addClass('loading');
                loadUrl(nextUrl);
            }
        };
        $nextPage.click(function (e) {
            if (!e[secondKey]) {
                e.preventDefault();
                loadNext();
            }
        });

        const syncHistory = function () {
            const scrollTop = window.pageYOffset;
            const windowHeight = $(window).height();
            const $pages = $('[data-page]');
            let node = Array.prototype.find.call($pages, function (node) {
                return node.offsetTop > scrollTop + windowHeight;
            });
            let newPage = currentPage;
            if (node) {
                newPage = parseInt(node.getAttribute('data-page')) - 1;
            } else {
                node = $pages.last().get(0);
                newPage = parseInt(node.getAttribute('data-page'));
            }
            if (newPage !== currentPage) {
                currentPage = newPage;
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('page', newPage);
                window.history.replaceState(null, null, window.location.pathname + "?" + searchParams.toString())
            }
        };

        const loadPage = function (page) {
            const href = page.attr('data-url');
            if (href && !page.hasClass('loading')) {
                page.addClass('loading');
                loadUrl(href);
            }
        };

        const loadCurrentPage = function () {
            const $current = $(`[data-page="${currentPage}"]`);
            const $prev = $current.prev();
            loadPage($current);
            loadPage($prev);
        };

        const scrollHandler = throttle(function () {
            syncHistory();
            loadCurrentPage();
            const scrollTop = window.pageYOffset;
            if ($nextPage.length && !$nextPage.hasClass('loading') && scrollTop + $(window).height() > $nextPage.get(0).offsetTop - 100) {
                if (currentPage % 3 !== 0) {
                    loadNext();
                }
            }
        }, 200);
        setTimeout(function () {
            $(window).on('scroll', scrollHandler);
        }, 200);
    }
});