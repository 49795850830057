import $ from "jquery";
import { Controller } from "stimulus"


export default class extends Controller {
    connect() {
        this.$element = $(this.element);
        this.$fileTarget = this.$element.find('input[type="file"]');
        this.$previewTarget = this.$element.find(".upload-field__preview");
        this.$deleteButton = this.$element.find(".upload-field__delete");
        this.$clearButton = this.$element.find(".upload-field__clear");
        this.$editButton = this.$element.find(".upload-field__edit");
        this.$destroyField = this.$element.find('input[type="hidden"]');

        this.$element.on('dragover', (e) => {
            e.stopPropagation();
            $(this.element).addClass('dragover');
        });

        this.$element.on('dragenter', () => {
            $(this.element).addClass('dragover');
        });

        this.$element.on('dragleave drop', (e) => {
            e.stopPropagation();
            $(this.element).removeClass('dragover');
        });

        this.$fileTarget.on('change', (e) => {
            this.handleChange(e);
        });

        this.$deleteButton.click(() => this.delete());
        this.$clearButton.click(() => this.clear());
        this.$editButton.on('click', () => {
            this.$fileTarget.click();
        });
        this.submitOnChange = this.element.getAttribute("data-change") === 'submit';
    }

    delete() {
        this.$fileTarget.val(null);
        this.$destroyField.val("1");
        this.$element.addClass("deleted");

        this.$element.find(".upload-field__preview.new").remove();
        this.$fileTarget.change();
    }

    clear() {
        if (this.$element.hasClass('deleted')) {
            this.$destroyField.val("0");
            this.$element.removeClass("deleted");
        } else {
            this.$fileTarget.val(null);
            this.$element.removeClass("updated");
            this.$element.find(".upload-field__preview.new").remove();
            this.$fileTarget.change();
        }
    }

    handleChange(e) {
        const $element = this.$element;
        const files = e.currentTarget.files;
        const file = files[0];
        if (file) {
            if (this.isFileAccepted(file)) {
                $element.find(".upload-field__filename").text(file.name);
                this.$destroyField.val("0");
                $element.removeClass('deleted');
                const $img = this.$previewTarget;
                const $fileTarget = this.$fileTarget;
                if ($img.length) {
                    const reader = new FileReader();
                    reader.addEventListener('load', function () {
                        const img = new Image();
                        img.onload = function () {
                            const expectedWidth = parseInt($element.attr('data-width'));
                            if (expectedWidth && expectedWidth !== this.width) {
                                e.preventDefault();
                                $fileTarget.val(null);
                                alert(`Please use an image with a width of ${expectedWidth}px`);
                                return;
                            }
                            $element.find(".upload-field__preview.new").remove();
                            $element.addClass('updated');
                            const $img = $(img).addClass("upload-field__preview new");
                            $element.append($img);
                        };
                        img.src = reader.result;
                    });
                    reader.readAsDataURL(file);
                } else {
                    $element.addClass('updated');
                }
                if (this.submitOnChange) {
                    $element.addClass('uploading');
                    e.currentTarget.form.submit();
                }
            } else {
                this.$fileTarget.val("");
            }
        }
    }

    isFileAccepted(file) {
        const accept = this.$fileTarget.attr('accept');
        if (accept.indexOf(".") > -1) {
            const extensions = accept.split(",").map((ext) => (ext || "").trim().toLowerCase());
            return !!extensions.find((ext) => file.name.toLowerCase().indexOf(ext) > -1);
        }
        return true;
    }
}
