import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["select"];

    connect() {
        $(this.selectTarget).change(this.checkSelection);
        this.checkSelection();
    }

    checkSelection = () => {
        const value = this.selectTarget.value;
        $(this.element).find('[data-id]').addClass('d-none');
        $(this.element).find(`[data-id="${value}"]`).removeClass('d-none');
    };
}