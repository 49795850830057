import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {

    connect() {
        $(this.element).find('[name="bundle[status]"]').change(this.checkSelection);
        this.checkSelection();
    }

    checkSelection = () => {
        const value = $(this.element).find('[name="bundle[status]"]:checked').attr('value');
        if(value === 'private') {
            $('#publicMessage').hide();
            $('#privateMessage').show();
        } else {
            $('#privateMessage').hide();
            $('#publicMessage').show();
        }
    };
}