import $ from "jquery";
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["publishContainer","featureContainer", "messageContainer"];

    connect() {
        this.$element = $(this.element);

        this.$element.find('input[name="bundle[review_status]"]').on('change', this.checkContainers);
        this.checkContainers();
    }

    checkContainers = () => {
        const $featureContainer = $(this.featureContainerTarget);
        const $messageContainer = $(this.messageContainerTarget);
        const $publishContainer = $(this.publishContainerTarget);

        const $radio = this.$element.find('input[type="radio"][name="bundle[review_status]"]:checked');
        const $checkbox = this.$element.find('input[type="checkbox"][name="bundle[review_status]"]:checked');

        $messageContainer.toggle(!!$radio.attr('data-message'));
        const feature = $checkbox.val() === "featured";
        const publish = $radio.val() === "public";
        $publishContainer.toggle(publish).find('input', 'textarea').attr('disabled', !publish);
        $featureContainer.toggle(feature).find('input', 'textarea').attr('disabled', !feature);
    };


}