import $ from "jquery";


const Rails = $.rails;
const confirmDialog = $('#confirmModal').modal({backdrop: true, show: false});
const confirmAttribute = 'data-confirm-me';
const labelAttribute = 'data-confirm-label';
const cancelLabelAttribute = 'data-cancel-label';
const btnClassAttribute = 'data-confirm-class';
var originalBtnClass = '';
const handleConfirm = function (element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element)
    }
};

const allowAction = element => {
    if (element.getAttribute(confirmAttribute) === null) {
        return true
    }

    showConfirmationDialog(element);
    return false
};

// Display the confirmation dialog
const showConfirmationDialog = element => {
    const message = element.getAttribute(confirmAttribute);
    const confirmLabel = element.getAttribute(labelAttribute) || 'Confirm';
    const cancelLabel = element.getAttribute(cancelLabelAttribute) || 'Cancel';
    const btnClass = element.getAttribute(btnClassAttribute);
    confirmDialog.find('.modal-body').html(message);
    const confirmButton = confirmDialog.find('button[data-confirm-btn]');
    if (originalBtnClass) {
        confirmButton.attr('class', originalBtnClass);
    }
    if (btnClass) {
        originalBtnClass = confirmButton.attr('className');
        confirmButton.attr('class', btnClass);
    }
    confirmDialog.find('button[data-dismiss]').text(cancelLabel);
    confirmButton.text(confirmLabel).one('click', () => {
        confirmed(element, true);
        confirmDialog.modal('hide');
    });
    confirmDialog.modal('show');

};

const confirmed = (element, success) => {
    if (success) {
        // User clicked confirm button
        element.removeAttribute(confirmAttribute);
        element.click()
    }
};

// Hook the event before the other rails events so it works togeter
// with `method: :delete`.
// See https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/start.coffee#L69
Rails.delegate(document, `a[${confirmAttribute}]`, 'click', handleConfirm);
Rails.delegate(document, `input[${confirmAttribute}]`, 'click', handleConfirm);
Rails.delegate(document, `button[${confirmAttribute}]`, 'click', handleConfirm);

