import $ from "jquery";
import 'pc-bootstrap4-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

$(document).ready(function(){
    $('[data-date-format]').each(function () {
        const $self = $(this);
        const format = $self.attr('data-date-format');
        const $hiddenInput = $('<input type="hidden"/>');
        $hiddenInput.attr('name', $self.attr('name')).val($self.val());
        $self.val(moment($self.val()).format(format));
        $self.attr('autocomplete', 'off');
        $hiddenInput.insertAfter($self);
        $self.datetimepicker({
            format: format,
            locale: 'en',
            icons: {
                up: "ci ci-up",
                down: "ci ci-down",
                previous: "ci ci-left",
                next: "ci ci-right"
            },
            sideBySide: true
        }).on('dp.change', function (e) {
            $hiddenInput.val(e.date ? e.date.toDate().toUTCString() : '');
        });
    });
});