/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '../scss/application.scss'

import 'whatwg-fetch';
import moment from 'moment';
import 'moment/locale/de';
import Clipboard from 'clipboard-polyfill';
import 'bootstrap';
import Selectize from 'selectize';
import hotkeys from 'hotkeys-js';
import $ from 'jquery';
import Rails from 'rails-ujs';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Archive } from 'libarchive.js/main.js';

import '../confirm-dialog';
import '../infinite-scrolling';
import '../date-input';
import '../sentry';

Rails.start();
window.jQuery = $;
window.$ = $;
window.moment = moment;

Archive.init({
    workerUrl: '/archivejs/worker-bundle.js?v2'
});

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));
const downloadDialog = $('#downloadModal').modal({ backdrop: true, show: false }).on('hide.bs.modal', function (e) {
    window.history.back();
});

$(window).on('dragover drop', function (e) {
    e.preventDefault();
});

$(document).ready(function () {
    /**
     * Selectize all inputs with data-tags attribute
     */
    const isMacLike = !!navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
    const secondKey = isMacLike ? 'metaKey' : 'ctrlKey';

    $('input[data-tags]').each(function () {
        // Copy and Paste plugin
        Selectize.define('copy_paste', function (options) {
            this.setup = (() => {
                const original = this.setup;
                return function () {
                    original.apply(this, arguments);
                    const self = this;
                    const control = this.$control;
                    const copyActiveItems = function () {
                        const activeItems = control.find('.item.active');
                        if (activeItems.length) {
                            Clipboard.writeText(activeItems.attr("data-value"));
                        }
                    };
                    $(document).keydown(function (e) {
                        if (e[secondKey] && e.which === 67) {
                            copyActiveItems();
                        } else if (e[secondKey] && e.which === 88) {
                            copyActiveItems();
                            const value = control.find('.item.active').attr('data-value');
                            if (value) {
                                self.removeItem(value);
                                self.setActiveItem(null);
                                delete self.renderCache['item'][value];
                                self.refreshItems();
                            }
                        }
                    });
                };
            })();
        });

        const valueToOption = function (input) {
            return {
                value: input,
                text: input
            }
        };
        const delimiter = ",";
        let options = [];
        if (this.value) {
            options = options.concat(this.value.split(delimiter).map(valueToOption));
        }
        if ($(this).attr('data-tags')) {
            options = options.concat($(this).attr('data-tags').split(delimiter).map(valueToOption));
        }
        const delimiterRegex = /[,;.]/;
        $(this).selectize({
            plugins: ['copy_paste'],
            onType: function (str) {
                const m = delimiterRegex.exec(str);
                if (m) {
                    this.createItem(str.slice(0, m.index));
                    this.removeItem(m[0]) // in case user enters just the delimiter

                }
            },
            splitOn: delimiterRegex,
            persist: false,
            options: options,
            openOnFocus: true,
            closeAfterSelect: true,
            create: valueToOption
        });
    });

    $('button[data-form]').click(function (e) {
        e.preventDefault();
        document.forms[$(this).attr('data-form')].submit();
    });

    $('.bundle-preview__size-toggle, .theme-preview__window-body, .style-preview__canvas').click(function () {
        $('.bundle-preview').toggleClass('open');
    });

    $('.theme-preview__mode-toggle').click(function (e) {
        e.stopPropagation();
        e.preventDefault();
        $('.theme-preview-wrapper').toggleClass('bg-dark');
    });

    $('.bundle-info__download-btn').click(function (e) {
        const $parent = $(e.currentTarget).parent();
        const id = $(e.currentTarget).attr('data-id');
        setTimeout(() => {
            fetch(`/api/v1/bundles/${id}`).then((response) => response.json()).then((json) => {
                $parent.find('.bundle-info__downloads').html(json.downloads);
            });
        }, 300);
    });

    $('.bundle-info__like-btn, .bundle-info__dislike-btn').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        const $self = $(e.currentTarget);
        const $parent = $self.parent();
        const id = $parent.attr('data-id');
        const href = $self.find("a").attr('href');
        $('.bundle-info__dislike-btn a').tooltip('dispose');
        $parent.toggleClass('liked');
        fetch(href, {credentials: 'include'}).then(function () {
            if (id) {
                $('.bundle-info__dislike-btn a').tooltip({
                    delay: 500,
                    container: 'body'
                });
                return fetch(`/api/v1/bundles/${id}`).then((response) => response.json()).then((json) => {
                    $parent.find('.bundle-info__likes').html(json.likes);
                });
            }
        })
    });

    $('input[name="bundle[zip_file]"]').change(function (e) {
        const files = e.currentTarget.files;
        if (files.length) {
            const file = files[0];
            const extension = file.name.split(".").pop();
            Archive.open(file).then((archive) => {
                archive.extractFiles(({file, path}) => {
                    if (/ulstyle$/i.test(extension)) {
                        const match = path.match(/^screenshot(_\d)?\.png$/);
                        if (match) {
                            const reader = new FileReader();
                            const suffix = match[1] || "";
                            const $uploadField = $(`.upload-field.screenshot${suffix}`);
                            reader.addEventListener('load', function () {
                                const $img = $('<img class="upload-field__preview from-bundle loaded" src=""/>');
                                $img.attr('src', reader.result);
                                $uploadField.append($img);
                            });
                            reader.readAsDataURL(file);
                        }
                    } else if (/ultheme$/i.test(extension)) {
                        const match = path.match(/^preview(_\w+)?\.png$/);
                        if (match) {
                            const mode = match[1] === '_dark' ? 'dark' : 'light';
                            const $uploadField = $(`.image-radio-selector.${mode} label`);
                            const reader = new FileReader();
                            reader.addEventListener('load', function () {
                                const $img = $('<img class="from-bundle loaded" src=""/>');
                                $img.attr('src', reader.result);
                                $uploadField.append($img);
                            });
                            reader.readAsDataURL(file);
                        }
                    }
                });
            });
        } else {
            $(`.from-bundle`).remove();
        }
    });

    $('.screenshot [data-toggle="modal"][data-target="#screenshotModal"]').click(function () {
        $('#screenshotModal .modal-body img').attr('src', $('.screenshot .upload-field__preview:last').attr('src'))
    });

    $('.screenshot_2 [data-toggle="modal"][data-target="#screenshotModal"]').click(function () {
        $('#screenshotModal .modal-body img').attr('src', $('.screenshot_2 .upload-field__preview:last').attr('src'))
    });

    $('*[data-hotkey]').each(function (index, el) {
        const hk = $(el).attr('data-hotkey');
        hotkeys(hk, function (e) {
            const href = $(el).attr('href');
            if (href) {
                const ev = $._data(el, 'events');
                if (ev && ev.click) {
                    e.preventDefault();
                    $(el).trigger('click')
                } else {
                    window.location = (href);
                }
            }
        });
    });

    $('.bundle-description__toggle').click(function () {
        $(this).parents('.bundle-description').toggleClass('open');
    });

    function applyTooltips() {
        $('[data-toggle="tooltip"]').tooltip({
            delay: 500,
            container: 'body'
        });
    }
    applyTooltips();

    function checkInstallPath() {
        const end = window.location.pathname.split("/").pop();
        if(end === 'install') {
            downloadDialog.modal('show');
        }
    }
    checkInstallPath();

    $('.bundle-info .install-bundle').click(function(e){
        if(e.currentTarget.getAttribute("href") === window.location.href) {
          e.preventDefault();
        };
        checkInstallPath();
    });
});

window.testErrorTracking = () => {
    $('.navbar-brand').click(function(e){
        e.preventDefault();
        e.shouldThrowSomeError();
    })
};

// import pw-power asynchronously because its really big
import('./pw-popover');
